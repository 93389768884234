<template>
    <ion-page>
        <Header
      type="0"
      title="点检表"
    >
    </Header>
    <ion-content scroll-y="false" class="content">
        <div class="photo">
            <img :src="photo.img?photo.img:require('@/assets/images/add2.png')"/>
        </div>
    
    </ion-content>
    <ion-footer>
         <ion-button expand="block">提交</ion-button>
    </ion-footer>
    </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import getPhoto from "@/components/getPhoto/index.vue"
export default {
    name:"sedExcel",
    components:{
        Header,
getPhoto
    },
    data(){
        return{
            photo:{
                img:''
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .photo{
        width: 100%;
        height: 100%;
        img{
            width: 200px;
            margin: 40% auto;
            display: block;
        }
    }
</style>